@import '../../styles/common/media_queries';

.course_mgt_wrapper {
  width: 100%;
  .header_main_wrapper {
    @include media_query(M) {
      padding-left: 65px;
    }
    @include media_query(L) {
      position: fixed;
      width: calc(100% - 65px);
      top: 0;
      z-index: 3;
      margin-bottom: 40px;
      padding-left: 0;
    }
    .header_wrapper {
      display: flex;
      align-items: center;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      // height: 110px;
      padding: 0 40px;
      @include media_query(XS) {
        min-height: 35px;
        padding-bottom: 15px;
        justify-content: center;
      }
      @include media_query(M) {
        min-height: auto;
        height: 110px;
        padding-bottom: 0;
        justify-content: left;
      }
      .heading {
        font-family: 'SourceSansPro';
        font-weight: 700;
        color: #2c2c2c;
        @include media_query(XS) {
          font-size: 24px;
        }
        @include media_query(M) {
          font-size: 32px;
        }
      }
    }
  }
  .search_main_wrapper {
    position: relative;

    /* width */

    @include media_query(XS) {
      display: block;
      min-width: calc(100% - 51px);
    }
    @include media_query(M) {
      margin-right: 15px;
      min-width: auto;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #e4e4e4;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #707070;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #707070;
    }

    &.active {
      .dropdown_container,
      .search_wrapper {
        border: 2px solid #38539a;
      }
    }

    .dropdown_container {
      position: absolute;
      width: 100%;
      top: 45px;
      background-color: #fff;
      border-radius: 10px;
      z-index: 1;
      border: 2px solid #707070;
      padding: 10px 10px 10px 0;
      .list_wrapper {
        height: 185px;
        overflow: auto;
        .list {
          font-family: 'SourceSansPro';
          display: flex;
          align-items: center;
          padding: 0 16px;
          font-size: 16px;
          font-weight: 500;
          color: #2c2c2c;
          cursor: pointer;
          @include media_query(XS) {
            height: auto;
            margin-bottom: 20px;
          }
          @include media_query(M) {
            min-height: 35px;
            margin-bottom: 0;
          }
          &:hover {
            font-weight: 700;
            color: #38539a;
          }
        }
      }
    }
  }
  .search_wrapper {
    display: flex;
    align-items: center;
    border: 2px solid #2c2c2c;
    border-radius: 10px;
    height: 35px;
    // width: 21rem;
    padding: 0 10px;
    &.outline {
      border: 2px solid #38539a;
    }
    @include media_query(XS) {
      // width: 15.5rem;
      // width: 18rem;
      width: 100%;
    }
    @include media_query(M) {
      width: 16.5rem;
    }
    @include media_query(L) {
      width: 20rem;
    }

    .icon_wrapper {
      margin-left: auto;
      .search {
        width: 18px;
        height: 18px;
        background-image: url(../../styles/images/search-black.svg);
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }
      .cross_icon {
        width: 12px;
        height: 12px;
        background-image: url(../../styles/images/cross_icon_slim.svg);
        cursor: pointer;
      }
    }
    .search_input {
      font-family: 'SourceSansPro';
      background-color: transparent;
      font-size: 16px;
      font-weight: 500;
      color: #2c2c2c;
      width: 100%;
      border: none;
      outline: none;
      @include media_query(M) {
        padding-right: 15px;
      }
      &::placeholder {
        font-weight: 400;
        color: #707070;
      }
    }
  }

  // Notibar
  .notification_wrapper {
    position: fixed;
    top: 15px;
    height: auto;
    z-index: 5;
    @include media_query(XS) {
      width: auto;
      margin: 0 15px;
    }
    @include media_query(M) {
      width: 450px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
    }

    .noti_bar {
      width: 100%;
      min-height: 60px;

      border-radius: 12px;
      padding: 12px 35px;
      position: relative;

      &.success_bar {
        background-color: #f0fae5;
        border: 1px solid #7fbc96;
      }
      &.failure_bar {
        background-color: #fff9f0;
        border: 1px solid #d1b278;
      }
      &.insufficient_bar {
        background-color: #fdf4f4;
        border: 1px solid #eb8593;
      }
      .text_wrapper {
        font-family: 'SourceSansPro';
        &.success_txt {
          color: #107f47;
        }
        &.failure_txt {
          color: #a36a00;
        }
        &.insufficient_txt {
          color: #d91734;
        }
        .tagline {
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 700;
        }
        .description {
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 400;
        }
      }
      .left_icon {
        position: absolute;
        top: 12px;
        left: 10px;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: cover;
        &.success_tick_icon {
          background-image: url(../../styles/images/success_icon.svg);
        }
        &.failure_icon {
          width: 18px;
          background-image: url(../../styles/images/failure_icon.svg);
        }
        &.insufficient_icon {
          width: 16px;
          background-image: url(../../styles/images/insufficient_icon.svg);
        }
      }

      .right_icon {
        position: absolute;
        top: 12px;
        right: 10px;
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        &.green_close_icon {
          background-image: url(../../styles/images/close_green_icon.svg);
        }
        &.red_close_icon {
          background-image: url(../../styles/images/close_red_icon.svg);
        }
        &.pink_close_icon {
          background-image: url(../../styles/images/close_pink_icon.svg);
        }
      }
    }
  }

  .audience_wrapper {
    // height: calc(100vh - 185px);
    // overflow-y: auto;
    overflow: hidden;
    &.course_inner_wrapper {
      @include media_query(M) {
        padding-top: 150px;
      }
      @include media_query(L) {
        height: 100%;
        overflow-y: visible;
      }
    }

    &.is_drawer_open {
      .top_area_wrapper {
        @include media_query(L) {
          width: calc(100% - 555px);
        }
        .search_main_wrapper {
          width: calc(100% - 345px);
          .search_wrapper {
            width: 100%;
          }
        }
      }

      .inner_wrapper {
        position: relative;

        .card_wrapper {
          @include media_query(M) {
            width: 50%;
          }
          @include media_query(L) {
            height: calc(100vh - 225px);
            overflow-y: auto;
          }

          .row_wrapper {
            .card_holder {
              @include media_query(M) {
                flex: 100%;
                max-width: 100%;
              }
              @include media_query(L) {
                flex: 33.33%;
                max-width: 33.33%;
              }
            }
          }
          // &.card_wrapper_with_drawer {
          //   width: calc(1005 - 550px);
          // }
        }
        ::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #e4e4e4;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #707070;
          border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #707070;
        }

        .table_main_wrapper {
          @include media_query(M) {
            width: 100%;
          }
          @include media_query(L) {
            width: calc(100% - 550px);
          }
          .tbl_header {
            .tbl_head {
              &:first-child {
                @include media_query(L) {
                  width: 25%;
                }
              }
            }
            .tbl_head_wrapper {
              width: 75%;
            }
          }
          .tbl_body {
            .tbl_row_wrapper {
              .tbl_row {
                .tbl_data {
                  &:first-child {
                    @include media_query(L) {
                      width: 25%;
                    }
                  }
                }
                .tbl_data_wrapper {
                  @include media_query(L) {
                    width: 75%;
                  }
                }
              }
            }
          }
        }

        .table_reduced_width {
          // width: 57% !important ;
        }

        .course_drawer_container {
          // display: block;
          // @include media_query(L) {
          //   position: relative;
          //   top: -65px;
          //   right: -25px;
          //   height: auto;
          // }
          .course_drawer_wrapper {
            @include media_query(L) {
            }
          }
        }
      }
    }

    .top_area_wrapper {
      display: flex;
      align-items: center;
      @include media_query(XS) {
        margin: 0 15px;
        margin-bottom: 24px;
      }
      @include media_query(M) {
        // display: flex;
        margin: 0;
        margin-bottom: 15px;
        padding: 0 40px;
      }

      @include media_query(L) {
        width: 100%;
      }

      .txt {
        font-family: 'SourceSansPro';
        font-size: 16px;
        color: #949494;
      }
      .sort_by_wrapper {
        order: 1;
        @include media_query(XS) {
          display: flex;
          align-items: center;
          margin-left: 15px;
        }
        @include media_query(M) {
          margin-left: 0;
          order: 0;
        }
        .txt {
          font-family: 'SourceSansPro';
          @include media_query(XS) {
            // display: none;
            font-size: 14px;
            font-weight: 700;
            color: #2c2c2c;
            text-transform: capitalize;
          }
          @include media_query(M) {
            display: none;
          }
        }
        .icon_wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 36px;
          height: 36px;
          background-color: #f4f4f4;
          border-radius: 50%;

          @include media_query(M) {
            background-color: #f4f4f4;
            border-radius: 50%;
            margin-right: 10px;
          }
          .filter {
            // position: absolute;
            // top: 0;
            // left: 10px;
            // right: 0;
            // bottom: 0;
            // margin: auto 0;
            width: 18px;
            height: 19px;
            background-image: url(../../styles/images/filter-black.svg);
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
          }
          &.selected {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            background-color: #fafafa;
            border-radius: 50%;
            .filter {
              background-image: url(../../styles/images/filter-black.svg);
            }
          }
        }
      }
      .toggle_section_wapper {
        position: relative;
        display: flex;
        align-items: center;
        width: 120px;
        height: 32px;
        margin-left: auto;
      }
      .toggle_wrapper {
        display: flex;
        width: 105px;
        height: 32px;
        padding: 1px;
        margin-left: auto;
        border: 1px solid #2c2c2c;
        border-radius: 20px;
        // background-color: #ffffff;
        @include media_query(XS) {
          display: none;
        }
        @include media_query(M) {
          display: flex;
        }
        .card_radius {
          border-radius: 24px;
        }
        .view_radius {
          border-radius: 24px;
        }
        .toggle_section {
          position: relative;
          top: -1px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          height: calc(100% + 2px);
          background-color: #ffffff;
          cursor: pointer;

          &.card_selected,
          &.view_selected {
            background-color: #2c2c2c;
            height: calc(100% + 2px);
            .card_view {
              background-image: url(../../styles/images/card-view-selected.svg);
            }
            .list_view {
              background-image: url(../../styles/images/list-view-selected.svg);
            }
          }
          &.card_selected {
            left: -1px;
            // border-radius: 20px 0 0 20px;
          }
          &.view_selected {
            right: -1px;
            // border-radius: 0 20px 20px 0;
          }
          .card_view {
            width: 18px;
            height: 18px;
            background-image: url(../../styles/images/card-view.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }
          .list_view {
            width: 18px;
            height: 13px;
            background-image: url(../../styles/images/list-view.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
      .btn_wrapper {
        // display: flex;
        align-items: center;
        margin-left: 16px;
        @include media_query(M) {
          display: flex;
          position: relative;
          height: auto;
          padding: 0;
        }
        &.btn_wrapper_xs {
          position: relative;
          margin-left: 16px;
          height: auto;
          width: auto;
          padding: 0;
          @include media_query(XS) {
            position: fixed;
            display: flex;
            align-items: center;
            background-color: #fff;
            bottom: 0;
            left: 0;
            margin-left: 0;
            width: 100%;
            padding: 0 16px;
            height: 70px;
            z-index: 2; //need to add 'z-index: 2' to avoid overlapping in mobile view
          }
          @include media_query(M) {
            position: relative;
            margin-left: 16px;
            height: auto;
            width: auto;
            padding: 0;
          }
        }
        .sort_wrapper {
          // display: flex;
          align-items: center;
          width: 220px;
          height: 35px;
          border: 2px solid #2c2c2c;
          border-radius: 10px;
          padding: 0 15px;
          margin: 0 10px;
          cursor: pointer;
          .txt {
            font-family: 'SourceSansPro';
            font-size: 14px;
            color: #707070;
          }
          @include media_query(XS) {
            display: none;
          }
          @include media_query(M) {
            display: flex;
          }
        }
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 170px;
          background: #38539a;
          border-radius: 24px;
          // padding: 0 30px;
          cursor: pointer;
          @include media_query(XS) {
            width: 100%;
            height: 35px;
          }
          @include media_query(M) {
            position: relative;
            width: auto;
            height: 35px;
            margin: 0;
          }
          .txt {
            font-family: 'SourceSansPro';
            font-size: 16px;
            font-weight: 700;
            color: #fff;
            padding-left: 10px;
            text-transform: capitalize;
          }
          .add {
            width: 12px;
            height: 13px;
            background-image: url(../../styles/images/add-white.svg);
            background-repeat: no-repeat;
          }
        }
      }
    }
    @include media_query(XS) {
      margin-top: 25px;
    }
    @include media_query(M) {
      padding-left: 65px;
      padding-top: 150px;
      margin-top: 0;
      height: calc(100vh - 15px);
    }
    @include media_query(L) {
      height: 100%;
      // padding-left: 0;
    }
    .inner_wrapper {
      display: flex;

      @include media_query(XS) {
        height: calc(100vh - 275px);
        overflow-y: auto;
        // padding: 0 15px;
        padding: 0 4px 0 15px;
      }
      @include media_query(M) {
        padding: 0;
        height: calc(100vh - 250px);
      }

      @include media_query(L) {
        // height: auto;
        overflow: visible;
      }

      // Card view
      .card_wrapper {
        width: 100%;
        height: 100%;

        .row_wrapper {
          display: flex;
          flex-wrap: wrap;
          margin: 0px;
          // height: 100%;
          @include media_query(XS) {
            padding-bottom: 75px;
          }
          @include media_query(M) {
            padding-bottom: 0;
            margin: 0 -12px;
            // height: auto;
          }
          @include media_query(L) {
            // height: 100%;
          }
          .card_holder {
            margin-bottom: 20px;
            @include media_query(XS) {
              flex: 100%;
              max-width: 100%;
              padding: 0;
              margin-bottom: 16px;
            }
            @include media_query(M) {
              flex: 50%;
              max-width: 50%;
              padding: 0 12px;
            }
            @include media_query(L) {
              flex: 20%;
              max-width: 20%;
            }
            &:last-child {
              margin-bottom: 0;
            }
            .card {
              width: 100%;
              background-color: #fff;
              cursor: pointer;

              @include media_query(XS) {
                height: 110px;
                display: flex;
                padding-bottom: 16px;
                border-bottom: 1px solid #e4e4e4;
                border-radius: 12px 12px 0 0;
              }
              @include media_query(M) {
                height: 215px;
                display: block;
                box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
                border-radius: 12px !important;
                padding-bottom: 0;
              }
              @include media_query(L) {
                height: 225px;
              }
              &.selected {
                .card_top {
                  .view_details {
                    position: absolute;
                    top: 0;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #ffffff;
                    box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
                    border-radius: 8px 0px 12px 0px;
                    z-index: 1;
                    .vd_icon {
                      width: 22px;
                      height: 20px;
                      background-image: url(../../styles/images/selected_course_icon.svg);
                      background-repeat: no-repeat;
                      background-size: cover;
                      cursor: pointer;
                    }
                  }
                }
              }
              .card_top {
                position: relative;
                border-radius: 12px !important;
                @include media_query(XS) {
                  width: 95px;
                  height: 100%;
                }
                @include media_query(M) {
                  width: 100%;
                  height: 130px;
                  padding: 0;
                }
                .view_details {
                  display: none;
                }
                .img_wrapper {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  .img {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    @include media_query(M) {
                      // border-radius: 0;
                      border-radius: 12px 12px 0px 0px !important;
                    }
                  }
                }
              }
              .card_bottom {
                @include media_query(XS) {
                  width: calc(100% - 95px);
                  padding: 10px;
                  padding-top: 0;
                  padding-right: 25px;
                }
                @include media_query(M) {
                  width: 100%;
                  padding: 8px 8px 12px 8px;
                }
                .status_wrapper {
                  margin-bottom: 10px;
                  display: flex;

                  @include media_query(M) {
                    display: flex;
                    align-items: center;
                  }

                  .text_wrapper {
                    display: flex;
                    align-items: center;
                    @include media_query(M) {
                    }
                    .draft_icon {
                      width: 18px;
                      height: 18px;
                      background-image: url(../../styles/images/draft_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                      display: block;
                      @include media_query(M) {
                        display: none;
                      }
                    }
                    .archive_cal_icon {
                      width: 18px;
                      height: 15px;
                      background-image: url(../../styles/images/archive_box_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                    }
                    .offline_cal_icon {
                      width: 16px;
                      height: 16px;
                      background-image: url(../../styles/images/offline_cal_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                    }
                    .calendar_icon {
                      width: 15px;
                      height: 18px;
                      background-image: url(../../styles/images/calender_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                    }
                    .date_text {
                      font-family: 'SourceSansPro';
                      font-size: 14px;
                      font-weight: 400;
                      color: #707070;
                      line-height: 1;
                    }
                  }
                  &.draft_cd {
                    .date_text {
                      // display: none;
                    }
                  }
                }

                .description {
                  font-family: 'SourceSansPro';
                  font-size: 14px;
                  font-weight: 400;
                  color: #2c2c2c;
                  max-height: 50px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  max-width: 95%;
                }
              }
            }
          }
        }
        @include media_query(M) {
          padding: 0 40px;
        }
        @include media_query(L) {
          // width: calc(100% - 555px);
          height: calc(100vh - 225px);
          overflow: hidden;
          overflow-y: auto;
        }
      }
      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #e4e4e4;
        border-radius: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #707070;
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #707070;
      }

      .card_wrapper_with_drawer {
        height: 100%;
        overflow-x: hidden;

        .row_wrapper {
          display: flex;
          flex-wrap: wrap;
          margin: 0px;
          @include media_query(M) {
            margin: 0 -12px;
          }
          .card_holder {
            margin-bottom: 20px;
            @include media_query(XS) {
              flex: 100%;
              max-width: 100%;
              padding: 0;
              margin-bottom: 16px;
            }
            @include media_query(M) {
              flex: 50%;
              max-width: 50%;
              padding: 0 12px;
            }
            @include media_query(L) {
              flex: 33.3%;
              max-width: 33.3%;
            }
            &:last-child {
              margin-bottom: 0;
            }
            .card {
              width: 100%;
              background-color: #fff;
              cursor: pointer;

              @include media_query(XS) {
                height: 110px;
                display: flex;
                padding-bottom: 16px;
                border-bottom: 1px solid #e4e4e4;
                border-radius: 12px 12px 0 0;
              }
              @include media_query(M) {
                height: 220px;
                display: block;
                box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
                border-radius: 12px;
                padding-bottom: 0;
              }
              @include media_query(L) {
              }
              &.selected {
                .card_top {
                  .view_details {
                    position: absolute;
                    top: 0;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #ffffff;
                    box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
                    border-radius: 8px 0px 12px 0px;
                    z-index: 1;

                    .vd_icon {
                      width: 22px;
                      height: 20px;
                      background-image: url(../../styles/images/selected_course_icon.svg);
                      background-repeat: no-repeat;
                      background-size: cover;
                      cursor: pointer;
                    }
                  }
                }
              }
              .card_top {
                position: relative;
                border-radius: 12px;
                @include media_query(XS) {
                  width: 95px;
                  height: 100%;
                }
                @include media_query(M) {
                  width: 100%;
                  height: 130px;
                  padding: 0;
                }
                .view_details {
                  display: none;
                }
                .img_wrapper {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  .img {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 12px 12px 0px 0px;
                    @include media_query(M) {
                      // border-radius: 0;
                    }
                  }
                }
              }
              .card_bottom {
                @include media_query(XS) {
                  width: calc(100% - 95px);
                  padding: 10px;
                  padding-right: 25px;
                }
                @include media_query(M) {
                  width: 100%;
                  padding: 8px;
                }
                .status_wrapper {
                  margin-bottom: 10px;
                  display: flex;

                  @include media_query(M) {
                    display: flex;
                    align-items: center;
                  }

                  .text_wrapper {
                    display: flex;
                    align-items: center;
                    @include media_query(M) {
                    }
                    .draft_icon {
                      width: 18px;
                      height: 18px;
                      background-image: url(../../styles/images/draft_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                      display: block;
                      @include media_query(M) {
                        display: none;
                      }
                    }
                    .archive_cal_icon {
                      width: 18px;
                      height: 15px;
                      background-image: url(../../styles/images/archive_box_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                    }
                    .offline_cal_icon {
                      width: 16px;
                      height: 16px;
                      background-image: url(../../styles/images/offline_cal_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                    }
                    .calendar_icon {
                      width: 15px;
                      height: 18px;
                      background-image: url(../../styles/images/calender_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                    }
                    .date_text {
                      font-family: 'SourceSansPro';
                      font-size: 14px;
                      font-weight: 400;
                      color: #707070;
                      line-height: 1;
                    }
                  }
                  &.draft_cd {
                    .date_text {
                      // display: none;
                    }
                  }
                }

                .description {
                  font-family: 'SourceSansPro';
                  font-size: 14px;
                  font-weight: 400;
                  color: #2c2c2c;
                  max-height: 50px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  max-width: 95%;
                }
              }
            }
          }
        }
        .img_wrapper {
          width: 100%;
          height: 275px;
          position: relative;
          margin-bottom: 25px;
          object-fit: cover;

          .img {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 12px;
          }
          &.empty_state {
            .empty_state_img {
              // background-image: url(../../styles/images/Empty_State_img.svg);
              background-image: url(../../styles/images/course_card_fallback.svg);
            }
          }
        }
        @include media_query(M) {
          padding: 0 40px;
          padding-right: 32px;
        }
        @include media_query(L) {
          height: calc(100vh - 225px);
          overflow-y: auto;
          width: 100%;
        }
      }
      // List View table
      .table_main_wrapper {
        display: none;
        width: 100%;
        @include media_query(M) {
          display: block;
        }
        // @include media_query(L) {
        //   height: calc(100vh - 225px);
        //   overflow: hidden;
        //   overflow-y: auto;
        // }
        .tbl_header {
          display: flex;
          align-items: center;
          background-color: #2c2c2c;
          border-radius: 12px;
          height: 50px;
          padding: 0 24px;
          margin: 0 40px;
          margin-bottom: 16px;
          @include media_query(S) {
            display: none;
          }
          @include media_query(M) {
            padding: 0 16px;
          }
          @include media_query(L) {
            padding: 0 40px;
          }
          .tbl_head_wrapper {
            display: flex;
            align-items: center;
            width: 85%;
            @include media_query(M) {
              width: 75%;
            }
            @include media_query(L) {
              width: 85%;
            }
            .tbl_head {
              &:nth-child(1) {
                width: 40%;
                @include media_query(L) {
                  width: 55%;
                }
              }
              &:nth-child(2) {
                width: 40%;
                @include media_query(L) {
                  width: 30%;
                }
              }
              &:nth-child(3) {
                width: 20%;
                display: flex;
                @include media_query(L) {
                  width: 15%;
                }
                // justify-content: center;
              }
            }
          }
          .tbl_head {
            font-family: 'SourceSansPro';
            font-size: 16px;
            font-weight: 500;
            color: #fafafa;

            &:nth-child(1) {
              width: 15%;
              @include media_query(M) {
                width: 25%;
              }
              @include media_query(L) {
                width: 15%;
              }
            }

            .active_user_icon {
              width: 25px;
              height: 18px;
              background-image: url(../../styles/images/users_icon.svg);
              background-size: cover;
              background-repeat: no-repeat;
              margin-right: 8px;
            }
            .user_txt {
              display: none;
              @include media_query(L) {
                display: inline;
              }
            }
          }
        }

        .tbl_body {
          overflow: auto;
          background-color: #fff;
          padding: 0;
          // height: calc(100vh - 245px);
          @include media_query(S) {
            height: calc(100vh - 260px);
          }
          // @include media_query(M) {
          //   padding: 0 40px;
          //   height: 100%;
          // }
          @include media_query(M) {
            padding: 0 40px;
            height: calc(100vh - 275px);
          }

          .tbl_row_wrapper {
            .tbl_row {
              display: flex;
              align-items: center;
              min-height: 100px;
              background-color: #fff;
              box-shadow: 0px 2px 8px rgba(44, 44, 44, 0.1);
              border-radius: 12px;
              padding: 0 24px;
              margin-bottom: 16px;
              cursor: pointer;
              @include media_query(S) {
                display: flex;
                min-height: auto;
                padding: 0;

                padding-bottom: 15px;
                margin-bottom: 16px;
                box-shadow: none;
                border-bottom: 1px solid #e4e4e4;
                &:last-child {
                  border-bottom: none;
                }
                @include media_query(M) {
                  margin: 0 15px;
                }
              }
              @include media_query(M) {
                padding: 0 16px;
              }
              @include media_query(L) {
                padding: 0 40px;
              }
              &:hover {
                background-color: #f4f4f4;
              }
              &.selected {
                background-color: #f1f2ff;
              }
              .tbl_data_wrapper {
                position: relative;
                width: 75%;
                display: flex;
                align-items: center;
                @include media_query(M) {
                  width: 75%;
                }
                @include media_query(L) {
                  width: 85%;
                }
                @include media_query(S) {
                  flex-direction: column;
                  align-items: flex-start;
                  padding-left: 10px;
                }
                .tbl_data {
                  &:nth-child(1) {
                    width: 40%;

                    @include media_query(S) {
                      width: 100%;
                      margin-bottom: 7px;
                    }
                    @include media_query(L) {
                      width: 55%;
                    }
                    .inner_data {
                      @include media_query(L) {
                        padding-right: 100px;
                      }
                      @include media_query(M) {
                        padding-right: 25px;
                      }
                      @include media_query(S) {
                        padding-right: 0px;
                      }
                    }
                    .txt {
                      font-family: 'SourceSansPro';
                      font-size: 16px;
                      font-weight: 500;
                      color: #2c2c2c;

                      @include media_query(M) {
                        font-family: 'SourceSansPro';
                        font-size: 14px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        max-width: 100%;
                      }
                      @include media_query(L) {
                        max-width: 80%;
                      }
                    }
                  }
                  &:nth-child(2) {
                    width: 40%;
                    .txt {
                      font-family: 'SourceSansPro';
                      color: #707070;
                      font-weight: 400;
                    }

                    @include media_query(S) {
                      width: 100%;
                    }
                    @include media_query(L) {
                      width: 30%;
                    }
                  }
                  // &:nth-child(3) {
                  //   width: 30%;
                  //   text-align: center;
                  //   @include media_query(S) {
                  //     width: 100%;
                  //     order: -1;
                  //     margin-bottom: 7px;
                  //   }
                  //   .txt_underline {
                  //     border-bottom: 2px solid #2c2c2c;
                  //     border-radius: 1px;
                  //   }
                  // }
                  &:nth-child(3) {
                    width: 20%;
                    @include media_query(L) {
                      width: 15%;
                    }
                    @include media_query(S) {
                      position: absolute;
                      bottom: 0;
                      right: 0;
                      width: 50px;
                    }
                    .inner_data {
                      display: flex;
                      align-items: center;
                    }
                    .txt {
                      font-family: 'SourceSansPro';
                      font-size: 14px;
                      color: #707070;
                    }
                    .courses_icon {
                      opacity: 0.5;
                    }
                  }
                }
              }
              .tbl_data {
                &:nth-child(1) {
                  width: 15%;
                  @include media_query(S) {
                    width: 96px;
                  }
                  @include media_query(M) {
                    width: 25%;
                  }
                  @include media_query(L) {
                    width: 15%;
                  }
                  .inner_data {
                    padding-right: 16px;
                    @include media_query(S) {
                      padding-right: 0;
                    }
                    img {
                      width: 100%;
                      max-height: 62px;
                      height: 62px;
                      border-radius: 5px;
                      object-fit: cover;
                      @include media_query(S) {
                        // height: 102px;
                        // max-height: 102px;
                        max-width: 96px;
                      }
                      @include media_query(M) {
                        width: 115px;
                      }
                    }
                    .empty_state_img {
                      width: 100%;
                      max-height: 62px;
                      height: 62px;
                      border-radius: 5px;
                      background-size: cover;
                      background-repeat: no-repeat;
                      object-fit: cover;
                      // @include media_query(S) {
                      //   height: 102px;
                      //   max-height: 102px;
                      //   max-width: 96px;
                      // }
                      // background-image: url(../../styles/images/Empty_State_img.svg);
                      background-image: url(../../styles/images/course_card_fallback.svg);

                      @include media_query(M) {
                        width: 115px;
                      }
                    }
                  }
                }
                @include media_query(L) {
                  width: 15%;
                }
              }
              .courses_icon {
                width: 14px;
                height: 16px;
                background-image: url(../../styles/images/files.svg);
                background-size: contain;
                background-repeat: no-repeat;
                margin-right: 12px;
              }
              .txt {
                font-family: 'SourceSansPro';
                font-size: 16px;
                color: #2c2c2c;
                @include media_query(M) {
                  font-size: 14px;
                }
              }
            }
          }
        }
        /* width */
        ::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #e4e4e4;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #707070;
          border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #707070;
        }
        .img_wrapper {
          width: 100%;
          height: 275px;
          position: relative;
          margin-bottom: 25px;
          object-fit: cover;
          .img {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 12px;
          }
          &.empty_state {
            .empty_state_img {
              // background-image: url(../../styles/images/Empty_State_img.svg);
              background-image: url(../../styles/images/course_card_fallback.svg);
            }
          }
        }
      }
      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #e4e4e4;
        border-radius: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #707070;
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #707070;
      }

      .table_reduced_width {
        // width: 57% !important;
      }

      // Course Drawer
      .course_drawer_container {
        // display: none;
        // width: 550px;
        // overflow: auto;
        // border-left: 1px solid #e4e4e4;
        // z-index: 3;
        // @include media_query(L) {
        //   padding: 8px 0;
        //   height: calc(100vh - 110px);
        // }
        // @include media_query(M) {
        //   //position: absolute; // can provide absolute but moving with screen not looking feasible
        //   position: fixed; // updated due to changes required
        //   top: 125px;
        //   right: 0;
        //   // width: 40%;
        //   height: calc(100% - 125px);
        //   background-color: white;
        //   flex-shrink: 0;
        // }
        @include media_query(M) {
          background: none;
          padding-left: 8px;
        }
        .custom_overlay {
          width: 100%;
          height: 175px;
          position: fixed;
          top: 0px;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 3;
          background-color: rgba(0, 0, 0, 0.47);
          @include media_query(M) {
            display: none;
          }
        }
        .course_drawer_wrapper {
          // background-color: #fff;
          // position: fixed;
          // top: 140px;
          // right: 0;
          // left: 0;
          // bottom: 0;
          // z-index: 10;
          // padding: 70px 15px 25px;
          // flex-shrink: 0;
          // border-radius: 20px 20px 0px 0px;
          // overflow-y: auto;
          // overflow-x: hidden;

          // @include media_query(M) {
          //   // display: block;
          //   // height: 100%;
          //   padding: 45px 40px;
          //   position: relative;
          //   top: 0;
          //   z-index: initial;
          //   position: relative;
          //   // border-radius: 0;
          // }

          @include media_query(L) {
            height: calc(100vh - 155px);
          }
          //
          // display: none;
          background-color: #fff;
          position: fixed;
          top: 140px;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 3;
          padding: 35px 0px 0px;
          padding-right: 4px;
          flex-shrink: 0;
          border-left: 1px solid #e4e4e4;
          border-radius: 20px 20px 0px 0px;
          // overflow-y: auto;
          @include media_query(M) {
            width: 550px;
            // padding: 45px 40px 0;
            padding: 0;
            padding-right: 8px;
            right: 0;
            left: auto;
            top: 200px;
            // z-index: initial;
            border-radius: 0;
          }
          @include media_query(L) {
            top: -50px;
            // padding: 0 40px 45px 24px;
            position: relative;
          }

          .mob_bar {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 35px;
            background-color: #fafafa;
            border-radius: 20px 20px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: center;

            .line_bar {
              width: 50px;
              height: 3px;
              background-color: #2c2c2c;
            }
            @include media_query(M) {
              display: none;
            }
          }
          .header {
            // height: 40px;
            height: auto;
            margin-bottom: 16px;
            display: flex;
            position: relative;
            .title {
              font-family: 'SourceSansPro';
              color: #2c2c2c;
              font-size: 21px;
              font-weight: 700;
              @include media_query(M) {
                font-family: 'SourceSansPro';
                font-size: 24px;
              }
            }
            .close_wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              right: 10px;
              width: 32px;
              height: 32px;
              cursor: pointer;
              @include media_query(XS) {
                // top: 0px;
                display: none;
              }
              @include media_query(M) {
                display: flex;
                top: 0;
              }
              .close_icon {
                width: 12px;
                height: 12px;
                background-image: url(../../styles/images/close.svg);
                background-repeat: no-repeat;
                background-size: contain;
                cursor: pointer;
              }
            }
          }

          .middle_container {
            overflow-y: auto;
            height: 100%;
            // padding: 0 15px;
            padding: 15px;

            @include media_query(M) {
              padding: 40px 32px 40px 32px;
            }
            .edit_course_btn_wrapper {
              background-color: #38539a;
              border-radius: 24px;
              width: 100%;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 25px;
              cursor: pointer;
              .txt {
                font-family: 'SourceSansPro';
                font-size: 16px;
                font-weight: 700;
                color: #ffffff;
                margin-right: 10px;
              }
              .edit_icon {
                width: 14px;
                height: 16px;
                background-image: url(../../styles/images/edit_white.svg);
                background-repeat: no-repeat;
                background-size: cover;
                cursor: pointer;
              }
            }
            .edit_course_btn_wrapper_disabled {
              background-color: #e5e5e5;
              border-radius: 24px;
              width: 100%;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 25px;
              cursor: pointer;
              .txt {
                font-family: 'SourceSansPro';
                font-size: 16px;
                font-weight: 700;
                color: #5e5e5e;
                margin-right: 10px;
              }
              .edit_icon {
                width: 14px;
                height: 16px;
                background-image: url(../../styles/images/disable_edit_course_icon.svg);
                background-repeat: no-repeat;
                background-size: cover;
                cursor: pointer;
              }
            }
            .img_wrapper {
              width: 100%;
              height: 275px;
              position: relative;
              margin-bottom: 25px;

              .img {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                border-radius: 12px;
              }
              &.empty_state {
                .empty_state_img {
                  // background-image: url(../../styles/images/Empty_State_img.svg);
                  background-image: url(../../styles/images/course_card_fallback.svg);
                }
              }
            }
            .course_title {
              font-family: 'SourceSansPro';
              font-size: 18px;
              font-weight: 700;
              color: #2c2c2c;
              margin-bottom: 15px;
              line-break: anywhere;
              @include media_query(M) {
                font-family: 'SourceSansPro';
                font-size: 24px;
              }
            }
            .description_wrapper {
              margin-bottom: 15px;
              line-break: anywhere;
              @include media_query(M) {
                line-height: 1.2;
              }
              .label {
                font-family: 'SourceSansPro';
                font-size: 14px;
                color: #2c2c2c;
                font-weight: 700;
                margin-bottom: 8px;
                @include media_query(M) {
                  font-family: 'SourceSansPro';
                  font-size: 16px;
                }
              }
              .value {
                font-family: 'SourceSansPro';
                font-size: 14px;
                color: #2c2c2c;
                font-weight: 400;
                @include media_query(M) {
                  font-family: 'SourceSansPro';
                  font-size: 16px;
                }
              }
            }
            .publish_title {
              font-family: 'SourceSansPro';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 18px;
              display: flex;
              align-items: center;
              color: #000000;
              margin-bottom: 15px;
              @include media_query(M) {
                font-family: 'SourceSansPro';
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 15px;
              }
            }
            .upload_details_wrapper {
              width: 100%;
              display: flex;
              justify-content: space-around;
              margin-bottom: 25px;

              .upload_info {
                flex: 33.33%;
                max-width: 33.33%;
                padding: 0 15px;
                border-left: 1px solid #e4e4e4;
                @include media_query(M) {
                  padding: 0 25px;
                }

                &:first-child {
                  padding-left: 0;
                  border: none;
                }
                &:last-child {
                  padding-right: 0;
                }
                .title {
                  font-family: 'SourceSansPro';
                  font-size: 14px;
                  font-weight: 700;
                  color: #2c2c2c;
                  margin-bottom: 10px;
                  @include media_query(M) {
                    font-family: 'SourceSansPro';
                    font-size: 16px;
                  }
                }
                .info_text {
                  font-family: 'SourceSansPro';
                  font-size: 14px;
                  font-weight: 500;
                  color: #707070;
                  @include media_query(M) {
                    font-family: 'SourceSansPro';
                    font-size: 16px;
                  }
                }
              }
            }
            .course_status {
              width: 100%;
              height: 65px;
              padding: 15px;
              border-radius: 12px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 25px;

              &.live {
                background-color: #f0fae5;
                .txt_wrapper {
                  .status_txt {
                    color: #107f47;
                  }
                }
              }
              &.offline {
                background-color: #f0f0f0;
                .txt_wrapper {
                  .status_txt {
                    color: #62625a;
                  }
                }
              }
              &.draft {
                background-color: #fff9f0;
                .txt_wrapper {
                  .status_txt {
                    color: #a36a00;
                  }
                }
              }

              &.archive {
                background-color: #fdf4f4;
                .txt_wrapper {
                  .status_txt {
                    color: #d91734;
                  }
                }
                .status_btn {
                  display: none;
                }
              }
              @include media_query(M) {
                min-height: 72px;
              }

              .txt_wrapper {
                display: inline-block;
                margin-right: 15px;
                .txt {
                  font-family: 'SourceSansPro';
                  display: inline;
                  font-size: 18px;
                  font-weight: 500;
                  color: #2c2c2c;
                  // margin-right: 8px;
                  @include media_query(M) {
                    font-family: 'SourceSansPro';
                    font-size: 24px;
                  }
                }
                .status_txt {
                  font-family: 'SourceSansPro';
                  display: inline;
                  font-size: 18px;
                  font-weight: 700;
                  line-height: 18px;

                  @include media_query(M) {
                    font-family: 'SourceSansPro';
                    font-size: 24px;
                    line-height: 24px;
                  }
                }
              }

              .status_btn {
                font-family: 'SourceSansPro';
                width: 120px;
                height: 35px;
                background-color: #ffffff;
                border: 2px solid #2c2c2c;
                box-sizing: border-box;
                border-radius: 24px;
                font-weight: 700;
                font-size: 14px;
                color: #2c2c2c;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                @include media_query(M) {
                  font-size: 16px;
                  min-width: 172px;
                  height: 50px;
                }
                &:hover {
                  color: #ffffff;
                  background-color: #2c2c2c;
                }
              }
            }
            .adapt_file_wrapper {
              font-family: 'SourceSansPro';
              width: 100%;
              height: 50px;
              background: #f4f4f4;
              border-radius: 12px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 15px;
              margin-bottom: 25px;
              .txt {
                font-family: 'SourceSansPro';
                font-size: 16px;
                font-weight: 700;
                color: #2c2c2c;
              }
              .file_name {
                font-size: 14px;
                font-weight: 700;
                color: #2c2c2c;
              }
            }
          }
          ::-webkit-scrollbar {
            width: 5px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            background: #e4e4e4;
            border-radius: 10px;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #707070;
            border-radius: 10px;
          }

          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #707070;
          }
        }
      }

      @include media_query(XS) {
        // padding: 0 0px;
      }
    }
  }
}

//Common code
.status {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  @include media_query(XS) {
    width: auto;
    min-width: 45px;
    padding: 0 10px;
    height: 25px;
    border-radius: 4px;
  }
  @include media_query(M) {
    border-radius: 5px;
  }
  &.live {
    @include media_query(XS) {
      background-color: #f0fae5;
      color: #107f47;
    }
  }
  &.archive {
    @include media_query(XS) {
      background-color: #fdf4f4;
      color: #d91734;
    }
  }
  &.draft {
    @include media_query(XS) {
      background-color: #fff9f0;
      color: #a36a00;
    }
  }

  &.offline {
    @include media_query(XS) {
      background-color: #f0f0f0;
      color: #5e5e5e;
    }
  }
}
.status_txt {
  font-family: 'SourceSansPro';
  @include media_query(XS) {
    display: block;
    font-size: 14px;
    font-weight: 500;
  }
}
.txt {
  font-family: 'SourceSansPro';
  font-size: 14px;
  font-weight: 500;
  color: #2c2c2c;
}
.img_wrapper {
  width: 100%;
  height: 275px;
  position: relative;
  object-fit: cover;
  margin-bottom: 25px;
  .img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    // background-position: center;
    border-radius: 12px;
  }
  &.empty_state {
    .empty_state_img {
      object-fit: cover;
      // background-image: url(../../styles/images/empty_img_mob.svg);
      background-image: url(../../styles/images/course_card_fallback_mob.svg);

      @include media_query(M) {
        // background-image: url(../../styles/images/Empty_State_img.svg);
        background-image: url(../../styles/images/course_card_fallback.svg);
      }
    }
  }
}

.img_instances_container {
  .dropdown_container {
    // padding: 20px 0px;
    margin-bottom: 50px;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    position: relative;
    .top_bar {
      width: 100%;
      height: 50px;
      background-color: #f4f4f4;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      align-items: center;
      position: relative;
      padding: 0 25px;
      cursor: pointer;
      .title {
        font-family: 'SourceSansPro';
        font-size: 14px;
        font-weight: 500;
        color: #2c2c2c;
        padding-right: 20px;

        @include media_query(M) {
          font-size: 18px;
        }
      }
      .toggle_arrow {
        position: absolute;
        right: 20px;
        width: 18px;
        height: 11px;
        background-repeat: no-repeat;
        background-size: contain;
        &.up_arrow {
          transform: rotate(180deg);
          background-image: url(../../styles/images/down_arrow_black.svg);
        }
        &.down_arrow {
          background-image: url(../../styles/images/down_arrow_black.svg);
        }
      }
    }
    .list_wrapper {
      // position: absolute;
      top: 100%;
      width: 100%;
      height: auto;
      .list {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        padding: 12px 25px;
        width: 100%;
        min-height: 50px;
        border-bottom: 1px solid #e4e4e4;
        &:last-child {
          border: none;
        }
        .item_wrapper {
          display: flex;
          align-items: center;
          min-width: 50%;
          // max-height: 45px;
          overflow: hidden;
          padding-right: 32px;

          .item {
            font-family: 'SourceSansPro';
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #2c2c2c;
            @include media_query(M) {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
        .adapt_file_title {
          font-family: 'SourceSansPro';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #2c2c2c;
        }
        .instance_status {
          width: 100px;
          height: 25px;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          font-family: 'SourceSansPro';
          @include media_query(M) {
            font-size: 16px;
            line-height: 24px;
          }
          &.live {
            color: #107f47;
            background-color: #f0fae5;
          }
          &.draft {
            color: #a36a00;
            background-color: #fff9f0;
          }
          &.offline {
            color: #62625a;
            background-color: #f0f0f0;
          }
          &.go_live_sts {
            color: #38539a;
            background-color: #f1f2ff;
          }
          &.archive {
            color: #d91734;
            background-color: #fdf4f4;
          }
        }
      }
    }
  }
}
