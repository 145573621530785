.drop_down{
    margin-left: 12px;
    position: relative;
    .selected_arrow_item{
        border-radius: 12px;
        border: 2px solid var(--1-Amway-Black, #2C2C2C);
        background: var(--2-Pure-White, #FFF);
        min-width: 320px;
        padding: 3px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .selected_item{
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
        .arrow_icon{
            width: 18px;
            height: 10px;
            background-image: url('../../../styles/images/down_arrow_black.svg');
            background-repeat: no-repeat;
            cursor: pointer;
        }
    }
    .list{
        margin-top: 10px;
        position:absolute;
        z-index: 1000;
        display: flex;
        min-width: 320px;
        padding: 2px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 12px;
        border: 2px solid var(--3-Dark-Purple, #38539A);
        background: var(--2-Pure-White, #FFF);
        .list_item{
            display: flex;
            height: 48px;
            padding: 8px 12px;
            align-items: center;
            gap: 20px;
        }
        .list_item_selected {
            display: flex;
            height: 48px;
            padding: 8px 12px;
            align-items: center;
            gap: 20px;
            color: var(--3-Dark-Purple, #38539A);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }
    }
    .list :hover{
        cursor: pointer;
        min-width: 320px;
        background-color: var(--28-Light-Gray-Small-Backgrounds, #F4F4F4);
    }
}